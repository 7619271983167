body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
.footer{
  margin-top:1em;
  text-align:center;
  padding-top:20px;
  padding-bottom:20px;
}